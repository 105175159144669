import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"
import Footer from "../components/Footer"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import TranslationTestimonialSlider from 'components/sliders/translation-testimonial-slider'


import "../scss/translatorstyle.scss"

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const TranslatorsPage: React.FC<PageProps<DataProps>> = ({ location }) => {

  const data = useStaticQuery(
    graphql`
      query {
        hero_background: file(relativePath: { eq: "infinity-icon-small1.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hero_img: file(relativePath: { eq: "translator-hero-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:574
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        car_hero: file(relativePath: { eq: "car-hero.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:50
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hipaa_icon: file(relativePath: { eq: "own-hours-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:160
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        reliable_icon: file(relativePath: { eq: "get-paid-fast.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:160
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cost_icon: file(relativePath: { eq: "support-you-need-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:160
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        interpretation_img: file(relativePath: { eq: "interpretation-on-your-terms-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        language_img: file(relativePath: { eq: "language-barrier.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        commitment_img: file(relativePath: { eq: "translators-our-commitment-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        section7_img: file(relativePath: { eq: "translator-get-started-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:508
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        btn_img: file(relativePath: { eq: "btn-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality:100
              width:600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  //declare image Data
  const hero_background = getImage(data.hero_background)
  const hero_img = getImage(data.hero_img)
  const car_hero = getImage(data.car_hero)
  const cost_icon = getImage(data.cost_icon)
  const reliable_icon = getImage(data.reliable_icon)
  const hipaa_icon = getImage(data.hipaa_icon)
  const middle_img = getImage(data.middle_img)
  const interpretation_img = getImage(data.interpretation_img)
  const language_img = getImage(data.language_img)
  const commitment_img = getImage(data.commitment_img)
  const section7_img = getImage(data.section7_img)
  const btn_img = getImage(data.btn_img)


  return (
    <>
      <Helmet bodyAttributes={{
        class: 'translator_body'
      }}>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <Layout>
        <Seo
          title="Work as an Interpreter | Join Transcend’s Team Today"
          description="Our nationwide network will empower you to work as an interpreter whenever you want and as much as you want. Join our team today."
        />
        <div className="backround-gradient">
          <section id="translator-hero">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 hero-title">
                  <h1 className="text-center">
                    JOIN OUR TRANSLATION <br /> TEAM AND GET PAID
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 left-content">
                  <GatsbyImage
                    image={hero_img}
                    alt=""
                    formats={["AUTO", "WEBP", "AVIF"]}
                    className="hero-img hero-mobile-img"
                  />
                  <p className="title text-white">
                    Translate on our Industry Leading Platform
                  </p>
                  <p className="sub-title text-white">
                    Use Transcend’s established network of clients needing translation to power your paycheck with ease.
                  </p>
                  <button className="get-btn text-white" data-bs-toggle="modal" data-bs-target="#getStartedPopupModal">Get Started</button>
                </div>
                <div className="col-lg-6">
                  <GatsbyImage
                    image={hero_img}
                    alt=""
                    formats={["AUTO", "WEBP", "AVIF"]}
                    className="hero-img"
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="translation-opportunities">
            <Container>
              <div className="col-lg-12 text-center">
                <h2 >Flexible Translation Opportunities <br />with Transcend</h2>
                <p className="text-white">In the job market with multi-lingual skills? Why not try working with Transcend? Our network will allow you to work full time or part time from almost anywhere in the world.</p>
              </div>
            </Container>
          </section>
          <section id="why-work-with-transcend" className="text-center">
            <Container>
              <div className="col-lg-12">
                <h2 className="text-center gradient-header">Why Work with Transcend’s <br /> Translation Team?</h2>
              </div>
              <div className="col-lg-4">
                <GatsbyImage
                  image={hipaa_icon}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section2-icon"
                />
                <p className="text-white title text-center">
                  SET YOUR OWN HOURS
                </p>
                <p className="text-white text-center section2-content">
                  You can work as much or as little as you want by setting your own schedule and only accepting jobs that meet your needs.
                </p>
              </div>
              <div className="col-lg-4">
                <GatsbyImage
                  image={cost_icon}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section2-icon"
                />
                <p className="text-white title text-center">
                  GET PAID FAST
                </p>
                <p className="text-white text-center section2-content">
                  We make it easy for you to get paid quickly. No waiting around for checks to come in the mail. Get paid instantly and directly to your account.
                </p>
              </div>
              <div className="col-lg-4">
                <GatsbyImage
                  image={reliable_icon}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section2-icon"
                />
                <p className="text-white title text-center ">
                  GET THE SUPPORT YOU NEED
                </p>
                <p className="text-white text-center section2-content">
                  You’ll never feel like you’re on your own with the help of our best in class support team. We’re always here to help you navigate challenging situations.
                </p>
              </div>
            </Container>
          </section>
          <section id="translators-requirements">
            <Container>
              <div className="col-lg-12 mb-4 pt-0 header-container">
                <h2 className="text-white text-center">Here’s What You Need to Become a <br /> Transcend Translator</h2>
              </div>
              <div className="col-lg-12">
                <h4 className="text-white mb-1 m-t-20"> Requirements: </h4>
                <ul>
                  <li className="text-white">BE FLUENT in English and the target language.</li>
                  <li className="text-white">Be able to interpret effectively, accurately, and impartially</li>
                  <li className="text-white">Be able to understand and present information of a medical and or legal nature</li>
                </ul>
              </div>
              <div className="col-lg-12">
                <h4 className="text-white mt-2 mb-1"> Desired: </h4>
                <ul>
                  <li className="text-white">Accreditations and certifications from</li>
                  <li className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi quis cras ut.</li>
                  <li className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna aenean urna sed.</li>
                </ul>
              </div>
            </Container>
          </section>
          <section id="get-started-translator">
            <Container>
              <div className="col-lg-7 get-content">
                <GatsbyImage
                  image={section7_img}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section7-img section7-mobile"
                />
                <h2>GET STARTED</h2>
                <p className="text-white">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut maecenas donec tortor lacinia mi suspendisse tempus cursus iaculis. Ornare et egestas sit cursus elementum sapien tincidunt at.
                </p>
                <button className="gets-btn text-white" data-bs-toggle="modal" data-bs-target="#getStartedPopupModal">
                  GET STARTED
                </button>
              </div>
              <div className="col-lg-5">
                <GatsbyImage
                  image={section7_img}
                  alt=""
                  formats={["AUTO", "WEBP", "AVIF"]}
                  className="section7-img"
                />
              </div>
            </Container>
          </section>
          <section id="faq">
            <Container>
              <div className="col-lg-12 text-center">
                <h2>Frequently Asked Questions</h2>
              </div>
              <div className="col-lg-12">
                <div className="accordion">
                  <details>
                    <summary id="longest-summary" aria-describedby="How does translating with transcend compare with other translation jobs?">How does translating with transcend compare with other translation jobs?</summary>
                    <div className="accordion-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mattis ut amet.
                      </p>
                    </div>
                  </details>
                  <details>
                    <summary className="one-liners-summary" aria-describedby="What are the requirements?">What are the requirements?</summary>
                    <div className="accordion-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mattis ut amet.
                      </p>
                    </div>
                  </details>
                  <details>
                    <summary className="two-liners-summary" aria-describedby="Can I translate with Transcend as well as other companies?">Can I translate with Transcend as well as other companies?</summary>
                    <div className="accordion-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mattis ut amet.
                      </p>
                    </div>
                  </details>
                  <details>
                    <summary className="two-liners-summary" aria-describedby="How can I find out how much I’ve made?">How can I find out how much I’ve made?</summary>
                    <div className="accordion-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mattis ut amet.
                      </p>
                    </div>
                  </details>
                  <details>
                    <summary className="two-liners-summary" aria-describedby="How often do I have to work an appointment?">How often do I have to work an appointment?</summary>
                    <div className="accordion-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mattis ut amet.
                      </p>
                    </div>
                  </details>
                  <details>
                    <summary className="one-liners-summary" aria-describedby="Can I work from anywhere?">Can I work from anywhere?</summary>
                    <div className="accordion-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mattis ut amet.
                      </p>
                    </div>
                  </details>
                </div>

              </div>
            </Container>
          </section>
          <section id="onboard-with-us">
            <Container>
              <div className="col-lg-12 text-center">
                <h2>
                  READY TO PUT YOUR LANGUAGE <br /> SKILLS TO WORK?
                </h2>
                <p className="text-white">
                  Onboard with us today and start working as soon as you’re approved.
                </p>
                <button className="gets-btn text-white" data-bs-toggle="modal" data-bs-target="#getStartedPopupModal">
                  GET STARTED
                </button>
              </div>
            </Container>
          </section>
          <Footer />
        </div>

      </Layout>
    </>
  )
}

export default TranslatorsPage
